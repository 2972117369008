import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import getURLs from 'get-urls';

dayjs.extend(calendar);

export const getDay = date => dayjs(date).format('D');
export const getMonth = date => dayjs(date).format('MMM');

export const getWeekDate = date =>
  dayjs(date).calendar(null, {
    sameDay: '[Today at] h:mm A',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'DD/MM/YYYY',
  });

export const getTitleURL = description => {
  const URLs = getURLs(description);
  if (!URLs.size) return undefined;
  return URLs.values().next().value;
};

export const getDescription = description => {
  const URLs = getURLs(description);
  if (!URLs.size) return description;

  let newDescription = description;
  URLs.forEach(text => {
    newDescription = newDescription.replace(text, '');
  });

  return newDescription;
};
