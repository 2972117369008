import styled from '@emotion/styled';
import { color, fontWeight, device } from '../../constants';

export const EventBody = styled.div``;

export const H2 = styled.h2`
  padding-top: 120px;
  margin-top: 0px;
  margin-bottom: 5px;

  width: 100%;

  color: ${color.black};
  font-size: 32px;

  @media ${device.tablet} {
    width: 661px;
    max-width: 661px;
    margin-bottom: 0px;

    font-size: 60px;
  }
`;

export const H4 = styled.h4`
  font-family: 'Begum';
  font-size: 22px;

  margin-top: 22px;
  margin-bottom: 0px;

  a {
    text-decoration: none;
    color: ${color.black};
  }
`;

export const Event = styled.div`
  font-family: 'Open Sans';

  margin-top: 20px;

  padding: 10px;
  padding-right: 15px;

  width: 90%;
  max-width: 500px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  background-color: white;

  @media ${device.tablet} {
    width: 500px;
    padding: 20px;

    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const EventDate = styled.div`
  color: ${color.grey8};

  padding: 10px 15px 0px 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Day = styled.p`
  font-size: 24px;
  font-weight: ${fontWeight.bold};

  margin-top: 10px;
  margin-bottom: 0px;
`;

export const Month = styled.p`
  margin-top: 0px;

  text-transform: uppercase;
`;

export const WeekDate = styled.p`
  color: ${color.grey6};

  margin-top: 0px;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  margin-top: 0px;
  margin-bottom: 10px;
  color: ${color.grey8};

  text-overflow: ellipsis;
`;
