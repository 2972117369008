import React from 'react';
import * as helpers from './helpers';
import { IProps } from './interfaces';
import {
  H4,
  WeekDate,
  Description,
  Event,
  EventDate,
  EventBody,
  Day,
  Month,
} from './components';

const createTitle = (event: IProps) => {
  const title = `${event.title}`;
  const URL = helpers.getTitleURL(event.description);
  if (!URL) return title;

  return (
    <a href={URL} target="_blank">
      {title}
    </a>
  );
};

export default (event: IProps) => (
  <Event>
    <EventDate>
      <Day>{helpers.getDay(event.start)}</Day>
      <Month>{helpers.getMonth(event.start)}</Month>
    </EventDate>
    <EventBody>
      <H4>{createTitle(event)}</H4>
      <WeekDate>{helpers.getWeekDate(event.start)}</WeekDate>
      <Description>{helpers.getDescription(event.description)}</Description>
    </EventBody>
  </Event>
);
