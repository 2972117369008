import _ from 'lodash';
import React from 'react';
import Layout from '../components/Layout';
import Head from '../components/Head';
import Header from '../components/Header';
import Events from '../components/Events';
import { color } from '../constants';

const schema = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  inLanguage: 'en',
  name: 'Wine Bar Events in London',
  url: 'https://seventyfive.cl/events',
  description: 'Upcoming Wine Bar Events in London',
  alternateName: ['75cl'],
  keywords: ['events', 'wine', 'wine bars', 'london'],
  sameAs: ['https://www.instagram.com/seventyfivecl'],
};

export default ({ pageContext }) => {
  const { citiesList, defaultCity } = pageContext;

  return (
    <Layout backgroundColor={color.cream1}>
      <Head
        title={schema.name}
        description={schema.description}
        schema={schema}
      />
      <Header citiesList={citiesList} defaultCity={defaultCity} sticky={true} />
      <Events />
    </Layout>
  );
};
