import Axios from 'axios';
import React from 'react';
import styled from '@emotion/styled';
import Event from './event';
// import Filters from './filters';
import { color, device } from '../../constants';
import { H2 } from './components';
// import fakeEvents from './__fixtures__';

const Container = styled.div`
  background-color: ${color.cream1};

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 20px;
  padding-left: 10px;
`;

const EventsContainer = styled.div`
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default class extends React.Component {
  state = {
    events: [],
    filter: undefined,
  };

  filterClear = () => this.setState({ filter: undefined });
  filterNextWeek = () => this.setState({ filter: 'NEXT_WEEK' });

  async componentDidMount() {
    const { filter } = this.state;

    const eventsFilter = filter ? `?filter=${filter}` : '';
    const res = await Axios.get(`/.netlify/functions/getEvents${eventsFilter}`);
    if (res.data) {
      this.setState({ events: res.data });
    }
  }

  render() {
    const { events } = this.state;

    return (
      <Container>
        <H2>Wine Events in London</H2>
        {/* <Filters
            onClickToday={this.filterClear}
            onClickNextWeek={this.filterNextWeek}
          /> */}
        <EventsContainer>
          {Array.isArray(events) && events.map(event => <Event {...event} />)}
        </EventsContainer>
      </Container>
    );
  }
}
